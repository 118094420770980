<template>
  <div id="behavior_style_comparative" class="white pt-10">
    <report-section-title>
      Comparativo de los estilos de comportamiento
    </report-section-title>

    <v-row no-gutters class="pt-4">
      <v-col cols="0" md="1" />
      <v-col cols="12" md="10">
        <v-row
          no-gutters
          class="mb-10"
          v-for="(data, factor) in factors"
          :key="factor"
        >
          <v-col cols="12" sm="3" class="print-only-badge">
            <factor-badge :factor="factor" />
          </v-col>
          <v-col cols="12" sm="9" class="print-only-bar">
            <horizontal-bar
              :value="naturalValues ? naturalValues[data.letter].percentage : 0"
              :color="data.color"
            />
            <horizontal-bar
              :value="
                adaptiveValues ? adaptiveValues[data.letter].percentage : 0
              "
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="0" md="1" />
    </v-row>
  </div>
</template>

<script>
import factors from "@/model/disc/factors";

import FactorBadge from "./FactorBadge.vue";
import HorizontalBar from "./HorizontalBar.vue";

export default {
  name: "SectionBehaviorGraph",
  components: {
    "horizontal-bar": HorizontalBar,
    "factor-badge": FactorBadge
  },
  props: {
    profiles: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      factors: factors
    };
  },
  computed: {
    naturalValues() {
      return this.profiles ? this.profiles.primary.values : null;
    },
    adaptiveValues() {
      return this.profiles ? this.profiles.conscious.values : null;
    }
  }
};
</script>

<style scoped>
@media print {
  .print-only-badge {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .print-only-bar {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
}
</style>
