<template>
  <div>
    <disc-questions v-if="modelType === 'DISC'" />
    <pgc-questions v-else-if="modelType === 'PGC'" />
    <oto-questions v-else-if="modelType === 'ONE_TO_ONE'" />
    <fot-questions v-else-if="modelType === 'F1_TEAM'" />
    <slt-questions v-else-if="modelType === 'SELF_LEADERSHIP'" />
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";

import assessmentMixin from "@/mixins/assessmentMixin";
import DISCQuestions from "./components/disc/DISCQuestions.vue";
import PGCQuestions from "./components/pgc/PGCQuestions.vue";
import OTOQuestions from "./components/one-to-one/OTOQuestions.vue";
import FOneQuestions from "./components/fone-team/FOneQuestions.vue";
import SelfLeadQuestions from "./components/self-leadership/SelfLeadQuestions.vue";

export default defineComponent({
  mixins: [assessmentMixin],
  components: {
    "disc-questions": DISCQuestions,
    "pgc-questions": PGCQuestions,
    "oto-questions": OTOQuestions,
    "fot-questions": FOneQuestions,
    "slt-questions": SelfLeadQuestions
  }
});
</script>
