<template>
  <div class="background pb-4">
    <v-row no-gutters v-if="profile">
      <v-col cols="0" md="1" />
      <v-col cols="12" md="10">
        <report-section-subtitle
          :text="title"
          :image="icon"
          :subtext="profile ? profile.name : ''"
        />
      </v-col>
      <v-col cols="0" md="1" />
    </v-row>

    <v-row no-gutters v-if="profile">
      <v-col cols="0" md="1" />
      <v-col cols="12" md="10">
        <profile-graph :values="profile ? profile.values : null" />
      </v-col>
      <v-col cols="0" md="1" />
    </v-row>

    <v-row no-gutters v-if="!!description">
      <v-col cols="2" />
      <v-col cols="8" class="generic-text-description">
        <div v-html="description" />
      </v-col>
      <v-col cols="2" />
    </v-row>
  </div>
</template>

<script>
import ProfileGraph from "./ProfileGraph.vue";

export default {
  name: "naturalStyle",
  components: {
    "profile-graph": ProfileGraph
  },
  props: {
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    profile: {
      type: Object,
      required: false
    },
    description: String
  }
};
</script>

<style scoped>
.background {
  background-color: #fff;
  padding-top: 3em;
}
</style>
