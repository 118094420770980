const profileNodes = [
  {
    type: "circle",
    code: "C1",
    class: "cls-1",
    cx: "1013.15",
    cy: "263.28",
    r: "16.46",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P1",
    cx: "1056.39",
    cy: "263.28",
    r: "16.46",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C6",
    class: "cls-1",
    cx: "1746.91",
    cy: "583.47",
    r: "16.46",
    transform: "translate(-265.89 12.98) rotate(-5.89)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P6",
    cx: "1780.46",
    cy: "610.74",
    r: "16.46",
    transform: "translate(-268.51 16.56) rotate(-5.89)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C2",
    class: "cls-1",
    cx: "1985.13",
    cy: "973.85",
    r: "16.46",
    transform: "translate(-308.69 1558.51) rotate(-46.07)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P2",
    cx: "1985.13",
    cy: "1017.08",
    r: "16.46",
    transform: "translate(-8.61 2193.83) rotate(-64.25)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C4",
    class: "cls-1",
    cx: "522.48",
    cy: "973.85",
    r: "16.46",
    transform: "translate(-797.39 770.54) rotate(-60.03)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P4",
    cx: "522.48",
    cy: "1017.08",
    r: "16.46",
    transform: "translate(-781.39 497.95) rotate(-45)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C19",
    class: "cls-1",
    cx: "624.76",
    cy: "973.85",
    r: "16.46",
    transform: "translate(-745.93 864.66) rotate(-60.3)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P19",
    cx: "624.76",
    cy: "1017.08",
    r: "16.46",
    transform: "translate(-751.43 570.27) rotate(-45)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C63",
    class: "cls-1",
    cx: "943.6",
    cy: "973.85",
    r: "16.46",
    transform: "translate(-303.78 -75) rotate(-5.47)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P63",
    cx: "943.6",
    cy: "1017.08",
    r: "16.46",
    transform: "translate(-351.55 -23.3) rotate(-8.26)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C61",
    class: "cls-1",
    cx: "1048.63",
    cy: "973.85",
    r: "16.46",
    transform: "translate(-594.94 893.1) rotate(-46.43)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P61",
    cx: "1048.63",
    cy: "1017.08",
    r: "16.46",
    transform: "translate(-536.26 1355.75) rotate(-64.48)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "g",
    code: "C18",
    content: [
      {
        type: "path",
        class: "cls-1",
        d: "M1182.64,989.81a16,16,0,0,1-.17-31.92h.17a16,16,0,0,1,.17,31.92Z",
        transform: "translate(-215.23 -169.4)"
      },
      {
        type: "path",
        d: "M1182.64,958.39a15.51,15.51,0,0,1,15.46,15.3,15.45,15.45,0,0,1-15.3,15.62h-.16a15.46,15.46,0,0,1-.16-30.92h.16m0-1h-.17a16.46,16.46,0,0,0,.17,32.92h.17a16.46,16.46,0,0,0-.17-32.92Z",
        transform: "translate(-215.23 -169.4)"
      }
    ]
  },
  {
    type: "circle",
    code: "P18",
    cx: "1182.64",
    cy: "1017.08",
    r: "16.46",
    transform: "translate(-356.96 1671.79) rotate(-72.59)"
  },
  {
    type: "g",
    code: "C9",
    content: [
      {
        type: "path",
        class: "cls-1",
        d: "M1315.56,989.81a16,16,0,0,1-.16-31.92h.17a16,16,0,0,1,.17,31.92Z",
        transform: "translate(-215.23 -169.4)",
        stroke: "#808080",
        strokeMiterLimit: "10"
      },
      {
        type: "path",
        d: "M1315.57,958.39a15.46,15.46,0,0,1,.16,30.92h-.17a15.51,15.51,0,0,1-15.45-15.3,15.45,15.45,0,0,1,15.29-15.62h.17m0-1h-.18a16.46,16.46,0,0,0,.17,32.92h.18a16.46,16.46,0,0,0-.17-32.92Z",
        transform: "translate(-215.23 -169.4)",
        stroke: "#808080",
        strokeMiterLimit: "10"
      }
    ]
  },
  {
    type: "circle",
    code: "P9",
    cx: "1315.57",
    cy: "1017.08",
    r: "16.46",
    transform: "translate(-387.29 1590.75) rotate(-64.25)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C74",
    class: "cls-1",
    cx: "1123.77",
    cy: "868.94",
    r: "16.46",
    transform: "translate(-451.15 1110.84) rotate(-54.54)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P74",
    cx: "1090.45",
    cy: "896.49",
    r: "16.46",
    transform: "translate(-322.15 1474.81) rotate(-71.41)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C12",
    class: "cls-1",
    cx: "753.79",
    cy: "584.97",
    r: "16.46",
    transform: "translate(-130.44 1095.89) rotate(-83.29)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P12",
    cx: "719.51",
    cy: "611.32",
    r: "16.46",
    transform: "translate(-437.76 504.86) rotate(-44.17)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "path",
    code: "C67",
    class: "cls-1",
    d: "M993.3,857.7a16.46,16.46,0,1,1,23.2,2A16.47,16.47,0,0,1,993.3,857.7Z",
    transform: "translate(-215.23 -169.4)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P67",
    cx: "972.56",
    cy: "874.63",
    r: "16.46",
    transform: "translate(-550.51 685.47) rotate(-41.1)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C62",
    class: "cls-1",
    cx: "914.69",
    cy: "803.33",
    r: "16.46",
    transform: "translate(-457.2 254.27) rotate(-23.12)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P62",
    cx: "881.36",
    cy: "830.88",
    r: "16.46",
    transform: "translate(-520.09 906.5) rotate(-54.98)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C30",
    class: "cls-1",
    cx: "1035.4",
    cy: "713.54",
    r: "16.46",
    transform: "translate(-116.39 1383.21) rotate(-76.43)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P30",
    cx: "1001.6",
    cy: "740.49",
    r: "16.46",
    transform: "translate(-53.8 1490.67) rotate(-84.06)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C28",
    class: "cls-1",
    cx: "978.53",
    cy: "640.36",
    r: "16.46",
    transform: "translate(-376.8 731.25) rotate(-46.06)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P28",
    cx: "944.72",
    cy: "667.31",
    r: "16.46",
    transform: "translate(-186.97 1203.54) rotate(-72.83)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C29",
    class: "cls-1",
    cx: "829.73",
    cy: "754.01",
    r: "16.46",
    transform: "translate(-221.33 1331.84) rotate(-84.06)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P29",
    cx: "795.92",
    cy: "780.96",
    r: "16.46",
    transform: "translate(-367.59 1198.19) rotate(-76.2)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C13",
    class: "cls-1",
    cx: "739.58",
    cy: "710.55",
    r: "16.46",
    transform: "translate(-496.4 441.46) rotate(-38.28)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P13",
    cx: "705.77",
    cy: "737.5",
    r: "16.46",
    transform: "translate(-330.67 1174.43) rotate(-82.7)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C11",
    class: "cls-1",
    cx: "926.31",
    cy: "571.79",
    r: "16.46",
    transform: "matrix(0.6, -0.8, 0.8, 0.6, -302.95, 798.29)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P11",
    cx: "892.04",
    cy: "598.15",
    r: "16.46",
    transform: "translate(-355.46 132.22) rotate(-17.82)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C59",
    class: "cls-1",
    cx: "1095.93",
    cy: "780.96",
    r: "16.46",
    transform: "translate(-60 1573.87) rotate(-81.12)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P59",
    cx: "1061.55",
    cy: "807.18",
    r: "16.46",
    transform: "translate(-219.71 1439.03) rotate(-74.18)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C60",
    class: "cls-1",
    cx: "733.01",
    cy: "891.18",
    r: "16.46",
    transform: "translate(-636.12 718.08) rotate(-50.38)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P60",
    cx: "733.01",
    cy: "934.41",
    r: "16.46",
    transform: "translate(-546.46 237.92) rotate(-25.54)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C70",
    class: "cls-1",
    cx: "838.57",
    cy: "909.64",
    r: "16.46",
    transform: "translate(-582.27 1017.98) rotate(-60.3)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P70",
    cx: "838.57",
    cy: "952.87",
    r: "16.46",
    transform: "translate(-643.4 702.65) rotate(-45)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C64",
    class: "cls-1",
    cx: "838.57",
    cy: "1035.96",
    r: "16.46",
    transform: "translate(-692 1081.72) rotate(-60.3)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P64",
    cx: "838.57",
    cy: "1079.19",
    r: "16.46",
    transform: "translate(-732.72 739.65) rotate(-45)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C68",
    class: "cls-1",
    cx: "733.01",
    cy: "1057.58",
    r: "16.46",
    transform: "translate(-768.73 913.93) rotate(-56.42)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P68",
    cx: "733.01",
    cy: "1100.81",
    r: "16.46",
    transform: "translate(-797.59 794.03) rotate(-50.38)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C15",
    class: "cls-1",
    cx: "1780.76",
    cy: "1382",
    r: "16.46",
    transform: "translate(50.88 2881.99) rotate(-85.6)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "path",
    code: "P15",
    d: "M1735.14,1420.49a16.46,16.46,0,1,1,23.21,1.79A16.45,16.45,0,0,1,1735.14,1420.49Z",
    transform: "translate(-215.23 -169.4)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C14",
    class: "cls-1",
    cx: "1808.09",
    cy: "1250.07",
    r: "16.46",
    transform: "translate(290.56 2851.51) rotate(-88.33)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P14",
    cx: "1774.96",
    cy: "1277.85",
    r: "16.46",
    transform: "translate(-384.75 2109.65) rotate(-62.99)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C44",
    class: "cls-1",
    cx: "1714.71",
    cy: "1211.77",
    r: "16.46",
    transform: "translate(159.63 2658.98) rotate(-85.6)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P44",
    cx: "1681.59",
    cy: "1239.55",
    r: "16.46",
    transform: "matrix(0.08, -1, 1, 0.08, 101.35, 2651.6)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C16",
    class: "cls-1",
    cx: "1621.24",
    cy: "1389.01",
    r: "16.46",
    transform: "translate(-296.53 -68.46) rotate(-3.48)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P16",
    cx: "1588.12",
    cy: "1416.79",
    r: "16.46",
    transform: "translate(-161.66 2722.04) rotate(-85.6)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "path",
    code: "C49",
    class: "cls-1",
    d: "M1554,1329.64a16.46,16.46,0,1,1,23.09,3A16.45,16.45,0,0,1,1554,1329.64Z",
    transform: "translate(-215.23 -169.4)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P49",
    cx: "1532.55",
    cy: "1345.65",
    r: "16.46",
    transform: "translate(-718.19 1049.15) rotate(-37.71)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C50",
    class: "cls-1",
    cx: "1508.97",
    cy: "1250.07",
    r: "16.46",
    transform: "translate(-663.82 1168.05) rotate(-42.19)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "path",
    code: "P50",
    d: "M1460.86,1285.41a16.46,16.46,0,1,1,23,3.35A16.45,16.45,0,0,1,1460.86,1285.41Z",
    transform: "translate(-215.23 -169.4)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C43",
    class: "cls-1",
    cx: "1623.16",
    cy: "1161.89",
    r: "16.46",
    transform: "translate(-412 172.43) rotate(-11.34)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P43",
    cx: "1589.65",
    cy: "1189.2",
    r: "16.46",
    transform: "translate(-544.93 1527.02) rotate(-51.6)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "path",
    code: "C46",
    class: "cls-1",
    d: "M1523.34,1128.64a16.46,16.46,0,1,1,23.19,2.12A16.46,16.46,0,0,1,1523.34,1128.64Z",
    transform: "translate(-215.23 -169.4)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P46",
    cx: "1502.48",
    cy: "1145.42",
    r: "16.46",
    transform: "translate(-359.03 1902.97) rotate(-66.7)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C48",
    class: "cls-1",
    cx: "1445.14",
    cy: "1186.22",
    r: "16.46",
    transform: "translate(-139.34 2289.9) rotate(-82.3)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P48",
    cx: "1410.47",
    cy: "1212.05",
    r: "16.46",
    transform: "translate(-194.96 2277.9) rotate(-82.3)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C72",
    class: "cls-1",
    cx: "1416.36",
    cy: "1095.64",
    r: "16.46",
    transform: "matrix(0.85, -0.53, 0.53, 0.85, -581.6, 757.83)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P72",
    cx: "1381.69",
    cy: "1121.47",
    r: "16.46",
    transform: "translate(-600.36 1164.46) rotate(-45.92)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C25",
    class: "cls-1",
    cx: "1274.11",
    cy: "1153.13",
    r: "16.46",
    transform: "translate(-659.53 972.31) rotate(-41.77)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P25",
    cx: "1230.88",
    cy: "1153.13",
    r: "16.46",
    transform: "translate(-190.33 2162.87) rotate(-87.49)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C47",
    class: "cls-1",
    cx: "1274.11",
    cy: "1233.61",
    r: "16.46",
    transform: "translate(-492.28 204.54) rotate(-15.08)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "path",
    code: "P47",
    d: "M1214.42,1233.71a16.46,16.46,0,1,1,16.56,16.36A16.46,16.46,0,0,1,1214.42,1233.71Z",
    transform: "translate(-215.23 -169.4)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "path",
    code: "C10",
    class: "cls-1",
    d: "M1257.65,1475.43a16.46,16.46,0,1,1,16.56,16.35A16.46,16.46,0,0,1,1257.65,1475.43Z",
    transform: "translate(-215.23 -169.4)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P10",
    cx: "1230.88",
    cy: "1475.32",
    r: "16.46",
    transform: "translate(-897.92 1133.08) rotate(-45)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C3",
    class: "cls-1",
    cx: "1274.11",
    cy: "1556.9",
    r: "16.46",
    transform: "matrix(0.97, -0.26, 0.26, 0.97, -576.38, 215.67)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "path",
    code: "P3",
    d: "M1214.42,1557a16.46,16.46,0,1,1,16.56,16.36A16.46,16.46,0,0,1,1214.42,1557Z",
    transform: "translate(-215.23 -169.4)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C21",
    class: "cls-1",
    cx: "534.77",
    cy: "1236.73",
    r: "16.46",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P21",
    cx: "716.2",
    cy: "1379.17",
    r: "16.46",
    transform: "translate(-1049.39 1102.86) rotate(-58.61)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "path",
    code: "C22",
    class: "cls-1",
    d: "M726.64,1273.09a16.46,16.46,0,1,1,2.75,23.12A16.47,16.47,0,0,1,726.64,1273.09Z",
    transform: "translate(-215.23 -169.4)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "path",
    code: "P22",
    d: "M692.84,1246.13a16.46,16.46,0,1,1,2.75,23.12A16.47,16.47,0,0,1,692.84,1246.13Z",
    transform: "translate(-215.23 -169.4)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "path",
    code: "C20",
    class: "cls-1",
    d: "M899.38,1408.77a16.46,16.46,0,1,1,2.75,23.11A16.45,16.45,0,0,1,899.38,1408.77Z",
    transform: "translate(-215.23 -169.4)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P20",
    cx: "878.52",
    cy: "1391.99",
    r: "16.46",
    transform: "matrix(0.51, -0.86, 0.86, 0.51, -981.89, 1275.77)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C53",
    class: "cls-1",
    cx: "970.73",
    cy: "1349.52",
    r: "16.46",
    transform: "translate(-832.33 687.87) rotate(-37.05)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P53",
    cx: "936.93",
    cy: "1322.56",
    r: "16.46",
    transform: "translate(-861.88 807.82) rotate(-42.38)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "path",
    code: "C57",
    class: "cls-1",
    d: "M811.69,1226.38a16.46,16.46,0,1,1,2.75,23.12A16.47,16.47,0,0,1,811.69,1226.38Z",
    transform: "translate(-215.23 -169.4)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P57",
    cx: "790.83",
    cy: "1209.61",
    r: "16.46",
    transform: "matrix(0.51, -0.86, 0.86, 0.51, -867.9, 1110.06)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C54",
    class: "cls-1",
    cx: "914.49",
    cy: "1190.79",
    r: "16.46",
    transform: "translate(-700.17 1565.33) rotate(-73.72)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P54",
    cx: "881.39",
    cy: "1162.98",
    r: "16.46",
    transform: "translate(-614.81 1692.5) rotate(-81.46)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C51",
    class: "cls-1",
    cx: "1033.37",
    cy: "1284.69",
    r: "16.46",
    transform: "translate(-797.22 785.33) rotate(-39.64)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P51",
    cx: "999.57",
    cy: "1257.73",
    r: "16.46",
    transform: "translate(-615.02 1877.51) rotate(-80.95)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "path",
    code: "C58",
    class: "cls-1",
    d: "M1080.72,1204a16.46,16.46,0,1,1,2.75,23.12A16.46,16.46,0,0,1,1080.72,1204Z",
    transform: "translate(-215.23 -169.4)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "path",
    code: "P58",
    d: "M1046.92,1177a16.46,16.46,0,1,1,2.75,23.12A16.47,16.47,0,0,1,1046.92,1177Z",
    transform: "translate(-215.23 -169.4)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "path",
    code: "C66",
    class: "cls-1",
    d: "M991.16,1132.26a16.46,16.46,0,1,1,2.75,23.12A16.45,16.45,0,0,1,991.16,1132.26Z",
    transform: "translate(-215.23 -169.4)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P66",
    cx: "970.3",
    cy: "1115.48",
    r: "16.46",
    transform: "translate(-698.08 1218.36) rotate(-59.59)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C73",
    class: "cls-1",
    cx: "1123.38",
    cy: "1122.62",
    r: "16.46",
    transform: "translate(-676.09 1064.67) rotate(-49)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P73",
    cx: "1089.58",
    cy: "1095.66",
    r: "16.46",
    transform: "translate(-643.72 637.96) rotate(-34.41)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "path",
    code: "C56",
    class: "cls-1",
    d: "M1178.23,1314.28a16.46,16.46,0,1,1,16.56,16.36A16.45,16.45,0,0,1,1178.23,1314.28Z",
    transform: "translate(-215.23 -169.4)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P56",
    cx: "1151.46",
    cy: "1314.18",
    r: "16.46",
    transform: "translate(-607.34 326.05) rotate(-20.83)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C52",
    class: "cls-1",
    cx: "1166.18",
    cy: "1397.46",
    r: "16.46",
    transform: "translate(-707.81 1977.32) rotate(-74.46)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P52",
    cx: "907.72",
    cy: "1228.06",
    r: "16.46",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C45",
    class: "cls-1",
    cx: "1164.72",
    cy: "1228.06",
    r: "16.46",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P45",
    cx: "1336.71",
    cy: "1397.46",
    r: "16.46",
    transform: "translate(-812.15 1200.44) rotate(-45.45)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "path",
    code: "C55",
    class: "cls-1",
    d: "M1342.34,1314.28a16.46,16.46,0,1,1,16.56,16.36A16.45,16.45,0,0,1,1342.34,1314.28Z",
    transform: "translate(-215.23 -169.4)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "path",
    code: "P55",
    d: "M1299.11,1314.28a16.46,16.46,0,1,1,16.56,16.36A16.45,16.45,0,0,1,1299.11,1314.28Z",
    transform: "translate(-215.23 -169.4)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C17",
    class: "cls-1",
    cx: "1878.48",
    cy: "973.85",
    r: "16.46",
    transform: "translate(-341.35 1481.7) rotate(-46.07)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P17",
    cx: "1878.48",
    cy: "1017.08",
    r: "16.46",
    transform: "translate(-68.93 2097.77) rotate(-64.25)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C42",
    class: "cls-1",
    cx: "1563.39",
    cy: "973.85",
    r: "16.46",
    transform: "translate(-269.1 1685.95) rotate(-60.51)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P42",
    cx: "1563.39",
    cy: "1017.08",
    r: "16.46",
    transform: "translate(-472.31 1253.3) rotate(-45.61)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C65",
    class: "cls-1",
    cx: "1458.81",
    cy: "973.85",
    r: "16.46",
    transform: "translate(44.6 2084.67) rotate(-80.6)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P65",
    cx: "1458.81",
    cy: "1017.08",
    r: "16.46",
    transform: "translate(-159.35 1940.56) rotate(-72.8)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C36",
    class: "cls-1",
    cx: "1773.5",
    cy: "1055.15",
    r: "16.46",
    transform: "translate(-505.32 870.4) rotate(-30.32)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P36",
    cx: "1773.5",
    cy: "1098.38",
    r: "16.46",
    transform: "translate(-487.35 520.12) rotate(-20.47)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C40",
    class: "cls-1",
    cx: "1773.5",
    cy: "899.89",
    r: "16.46",
    transform: "translate(-325.45 1368.23) rotate(-45.61)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P40",
    cx: "1773.5",
    cy: "943.13",
    r: "16.46",
    transform: "translate(-448.76 855.07) rotate(-30.32)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C35",
    class: "cls-1",
    cx: "1668.47",
    cy: "908.35",
    r: "16.46",
    transform: "translate(125.1 2097.24) rotate(-74.21)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P35",
    cx: "1668.47",
    cy: "951.58",
    r: "16.46",
    transform: "translate(-389.62 1323.63) rotate(-46.07)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C38",
    class: "cls-1",
    cx: "1668.47",
    cy: "1033.53",
    r: "16.46",
    transform: "translate(-202.51 1917.91) rotate(-64.25)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P38",
    cx: "1668.47",
    cy: "1076.76",
    r: "16.46",
    transform: "translate(-36.95 2219.82) rotate(-74.21)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C5",
    class: "cls-1",
    cx: "1587.69",
    cy: "574.4",
    r: "16.46",
    transform: "translate(-265.8 -3.41) rotate(-5.89)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P5",
    cx: "1621.24",
    cy: "601.68",
    r: "16.46",
    transform: "translate(199.64 1671.27) rotate(-66.13)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C24",
    class: "cls-1",
    cx: "1531.74",
    cy: "646.58",
    r: "16.46",
    transform: "matrix(0.99, -0.1, 0.1, 0.99, -273.5, -8.77)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P24",
    cx: "1565.29",
    cy: "673.85",
    r: "16.46",
    transform: "translate(-276.12 -5.19) rotate(-5.89)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C23",
    class: "cls-1",
    cx: "1473.74",
    cy: "716.34",
    r: "16.46",
    transform: "translate(-280.97 -14.36) rotate(-5.89)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P23",
    cx: "1507.29",
    cy: "743.61",
    r: "16.46",
    transform: "translate(-245.61 -104.35) rotate(-2.45)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C34",
    class: "cls-1",
    cx: "1408.8",
    cy: "783.26",
    r: "16.46",
    transform: "translate(-288.18 -20.67) rotate(-5.89)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P34",
    cx: "1442.35",
    cy: "810.53",
    r: "16.46",
    transform: "translate(-248.52 -107.07) rotate(-2.45)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C71",
    class: "cls-1",
    cx: "1381.24",
    cy: "868.25",
    r: "16.46",
    transform: "translate(-297.05 -23.05) rotate(-5.89)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P71",
    cx: "1414.79",
    cy: "895.52",
    r: "16.46",
    transform: "translate(-191.96 1657.4) rotate(-66.13)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C39",
    class: "cls-1",
    cx: "1493.16",
    cy: "849.58",
    r: "16.46",
    transform: "translate(-294.54 -11.66) rotate(-5.89)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "path",
    code: "P39",
    d: "M1539.48,887.24a16.46,16.46,0,1,1-2.39-23.16A16.47,16.47,0,0,1,1539.48,887.24Z",
    transform: "translate(-215.23 -169.4)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C26",
    class: "cls-1",
    cx: "1587.38",
    cy: "800.5",
    r: "16.46",
    transform: "translate(-2.33 1758.66) rotate(-66.13)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P26",
    cx: "1620.93",
    cy: "827.77",
    r: "16.46",
    transform: "translate(-291.63 1.34) rotate(-5.89)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C37",
    class: "cls-1",
    cx: "1684.88",
    cy: "757.86",
    r: "16.46",
    transform: "translate(-246.05 -96.76) rotate(-2.45)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P37",
    cx: "1718.43",
    cy: "785.13",
    r: "16.46",
    transform: "matrix(0.99, -0.1, 0.1, 0.99, -286.73, 11.12)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C7",
    class: "cls-1",
    cx: "1767.59",
    cy: "713.51",
    r: "16.46",
    transform: "translate(-244.08 -93.27) rotate(-2.45)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P7",
    cx: "1801.13",
    cy: "740.79",
    r: "16.46",
    transform: "translate(-281.75 19.37) rotate(-5.89)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C8",
    class: "cls-1",
    cx: "1227.38",
    cy: "515.53",
    r: "16.46",
    transform: "translate(-7.79 1201.68) rotate(-62.77)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P8",
    cx: "1271.62",
    cy: "515.53",
    r: "16.46",
    transform: "matrix(0.25, -0.97, 0.97, 0.25, 233.69, 1444.29)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C31",
    class: "cls-1",
    cx: "1012.15",
    cy: "590.68",
    r: "16.46",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P31",
    cx: "1056.39",
    cy: "590.68",
    r: "16.46",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C33",
    class: "cls-1",
    cx: "1227.38",
    cy: "837.82",
    r: "16.46",
    transform: "translate(-178.69 1557.4) rotate(-71.06)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P33",
    cx: "1271.62",
    cy: "837.82",
    r: "16.46",
    transform: "translate(-389.85 1145.23) rotate(-51.63)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C32",
    class: "cls-1",
    cx: "1122.5",
    cy: "596.1",
    r: "16.46",
    transform: "translate(128.46 1431.1) rotate(-80.18)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P32",
    cx: "1165.74",
    cy: "596.1",
    r: "16.46",
    transform: "translate(-295.3 829.5) rotate(-45)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C69",
    class: "cls-1",
    cx: "930.1",
    cy: "507.28",
    r: "16.46",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P69",
    cx: "972.33",
    cy: "507.28",
    r: "16.46",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C27",
    class: "cls-1",
    cx: "1101.34",
    cy: "507.28",
    r: "16.46",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P27",
    cx: "1142.57",
    cy: "507.28",
    r: "16.46",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "C41",
    class: "cls-1",
    cx: "1339.99",
    cy: "596.1",
    r: "16.46",
    transform: "translate(236.74 1593.9) rotate(-76.72)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  },
  {
    type: "circle",
    code: "P41",
    cx: "1382.23",
    cy: "596.1",
    r: "16.46",
    transform: "translate(357.18 1695.6) rotate(-80.78)",
    stroke: "#808080",
    strokeMiterLimit: "10"
  }
];

export const getNode = code => {
  return profileNodes.find(node => node.code === code);
};
