import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
// TODO: Improve this export by injecting the signout callback in some way
import firebase from "@/plugins/firebase";
import store from "./store/index";
import "./registerServiceWorker";
import VueGoogleCharts from "vue-google-charts";

// Components to be imported
import BackButton from "@/components/buttons/BackButton";

import AutocompleteField from "@/components/forms/AutocompleteField";
import Captcha from "@/components/forms/Captcha";
import EmailField from "@/components/forms/EmailField";
import PasswordField from "@/components/forms/PasswordField";
import Select from "@/components/forms/Select";
import TextField from "@/components/forms/TextField";

import MessageDialog from "@/components/dialogs/MessageDialog";
import TeamDialog from "@/components/dialogs/TeamDialog";

import AssessmentBackNextCardLayout from "@/components/layouts/AssessmentBackNextCardLayout";
import CardFormLayout from "@/components/layouts/CardFormLayout";
import CardLayout from "@/components/layouts/CardLayout";
import PageLayout from "@/components/layouts/PageLayout";

import DropdownMenu from "@/components/menus/DropdownMenu";

import ReportIcon from "@/components/reports/ReportIcon";
import ReportSectionSubtitle from "@/components/reports/ReportSectionSubtitle";
import ReportSectionTitle from "@/components/reports/ReportSectionTitle";
import XSRotationMessage from "@/components/reports/XSRotationMessage";

import Tooltip from "@/components/tooltip/Tooltip";

import VueHtmlToPaper from "vue-html-to-paper";

Vue.use(VueGoogleCharts);

Vue.config.productionTip = false;
let style_url = "";
const production_url = "";
if (window.location.hostname == "localhost") {
  style_url = "http://localhost:8080/css/print.css";
} else {
  style_url = `${production_url}/css/print.css`;
}
const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    // 'https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css',
    // 'https://unpkg.com/kidlat-css/css/kidlat.css',
    // "https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css",
    // "https://cdn.jsdelivr.net/npm/@mdi/font@6.x/css/materialdesignicons.min.css",
    // "https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900",
    // style_url
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title // override the window title
};

Vue.use(VueHtmlToPaper, options);

Vue.component("o-autocomplete-field", AutocompleteField);
Vue.component("o-back-button", BackButton);
Vue.component("o-captcha", Captcha);
Vue.component("o-card-layout", CardLayout);
Vue.component("o-card-form-layout", CardFormLayout);
Vue.component("o-dropdown-menu", DropdownMenu);
Vue.component("o-email-field", EmailField);
Vue.component("o-message-dialog", MessageDialog);
Vue.component("o-message-dialog-team", TeamDialog);
Vue.component("o-page-layout", PageLayout);
Vue.component("o-password-field", PasswordField);
Vue.component("o-select", Select);
Vue.component("o-text-field", TextField);
Vue.component("o-tooltip", Tooltip);

Vue.component("assessment-back-next-card-layout", AssessmentBackNextCardLayout);
Vue.component("report-icon", ReportIcon);
Vue.component("report-section-subtitle", ReportSectionSubtitle);
Vue.component("report-section-title", ReportSectionTitle);
Vue.component("xs-rotation-message", XSRotationMessage);

Vue.filter("currency", value => {
  const number = Number.parseFloat(value);
  if (Number.isNaN(number)) {
    return Number.NaN;
  } else {
    return number.toLocaleString("es-ES");
  }
});

if (location.hostname !== "localhost") {
  Sentry.init({
    Vue,
    dsn: "https://1afedebdfd6c40018b000a4a8863e87c@o1251890.ingest.sentry.io/6417692",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "my-site-url.com", /^\//]
      })
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5
  });
}

const vue = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");

// TODO: Improve this export by injecting the signout callback in some way
firebase.auth().onAuthStateChanged(function (user) {
  if (!user) {
    vue.$store.commit("setUser", null);
  }
});
