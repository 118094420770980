import { PRIMARY, CONSCIOUS } from "./constants";
import v1 from "./percentages-v1";
import v2 from "./percentages-v2";

export default {
  get(data) {
    const response = { ...data };
    const primary = response.profiles[PRIMARY];
    const conscious = response.profiles[CONSCIOUS];
    let percentages = null;
    if (data.version == "1") {
      percentages = v1;
    } else if (data.version == "2") {
      percentages = v2;
    } else {
      throw new Error("Invalid model version");
    }

    primary.values["D"] = percentages[PRIMARY]["D"][primary.values["D"]];
    primary.values["I"] = percentages[PRIMARY]["I"][primary.values["I"]];
    primary.values["S"] = percentages[PRIMARY]["S"][primary.values["S"]];
    primary.values["C"] = percentages[PRIMARY]["C"][primary.values["C"]];

    conscious.values["D"] = percentages[CONSCIOUS]["D"][conscious.values["D"]];
    conscious.values["I"] = percentages[CONSCIOUS]["I"][conscious.values["I"]];
    conscious.values["S"] = percentages[CONSCIOUS]["S"][conscious.values["S"]];
    conscious.values["C"] = percentages[CONSCIOUS]["C"][conscious.values["C"]];

    return response;
  }
};
