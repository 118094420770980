<template>
  <div id="" class="section-background">
    <report-section-title>
      Diagnóstico de Convergencia Persona / Objetivos
    </report-section-title>

    <v-container fluid>
      <v-row no-gutters>
        <v-col no-gutters cols="0" md="1" />
        <v-col no-gutters cols="12" md="10" class="px-sm-1 pb-4">
          <report-section-subtitle
            text="Objetivo del diagnóstico"
            class="mb-4"
          />

          <ol class="intro-text">
            <li>
              Conocer el nivel de madurez psicorrelacional y el porcentaje de
              convergencia del colaborador con los objetivos empresariales.
            </li>
          </ol>
        </v-col>
        <v-col cols="0" md="1" />
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({ name: "SectionReadyToKnow" });
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

.section-background {
  background-color: #fff;
  color: var(--v-primary-base);
  font-family: Roboto;
}
.intro-text {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: var(--v-primary-base);
  text-align: justify;
}
.four-factors {
  background: linear-gradient(
    45deg,
    var(--v-primary-base) 0%,
    var(--v-primary-lighten2) 100%
  );
  padding-bottom: 1em;
}
@media print {
  .intro-text {
    margin-top: -1em;
  }
  .four-factors {
    padding-bottom: 1.3em;
  }
  .col-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
}
</style>
