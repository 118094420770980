import axios from "axios";

import firebaseConfig from "@/config/firebaseConfig";
import assessmentModel from "@/model/assessment/assessment";
// TODO: Migrate percentages to new model
import percentages from "@/model/assessment/percentages";
import descriptors from "@/model/assessment/descriptors";
import { ASSESSMENT_STATUS } from "@/model/assessment/constants";
import disc from "@/model/disc";
import f1TeamQuestions from "@/model/fone-team/questions";
import f1TeamLevels from "@/model/fone-team/levels";
import f from "@/plugins/formatter";
import firebase from "@/plugins/firebase";
import { sumArray } from "@/utils/arrays";

const instance = axios.create({
  baseURL: firebaseConfig.apiBaseURL
});

export default {
  create: async assessment => {
    const newAssessment = f.objectCamelToSnakeCase(assessment, true);
    const assessmentId = await instance.post("/assessment", newAssessment);
    return assessmentId;
  },
  get: async assessmentId => {
    const doc = await firebase
      .firestore()
      .collection("assessments")
      .doc(assessmentId)
      .get();
    if (doc.exists) {
      const assessment = doc.data();
      assessment.id = assessmentId;
      return f.objectSnakeToCamelCase(assessment, true);
    } else {
      return null;
    }
  },
  submitAssessment: async assessment => {
    const answers = assessment.answers;

    const newAssessment = f.objectCamelToSnakeCase(assessment, true);
    delete newAssessment.id;
    delete newAssessment.answers;
    newAssessment.version = "2";

    switch (assessment.modelType) {
      case "DISC":
        newAssessment.profiles = parseDISCAnswers(answers);
        break;

      case "PGC":
        newAssessment.results = answers;
        break;

      case "ONE_TO_ONE":
        newAssessment.results = answers;
        break;

      case "F1_TEAM":
        newAssessment.results = answers;
        break;

      case "SELF_LEADERSHIP":
        newAssessment.results = answers;
        break;
      default:
        throw new Error(
          `Valor ${assessment.modelType} inválido para 'modelType'`
        );
    }

    try {
      await instance.put(`/assessment/${assessment.id}/submit`, newAssessment);
    } catch (error) {
      throw error;
    }
  },
  getReportData: async reportId => {
    if (reportId) {
      try {
        const snapshot = await firebase
          .firestore()
          .collection("assessments")
          .where("report_id", "==", reportId)
          .get();

        if (!snapshot.empty) {
          const doc = snapshot.docs[0];
          if (doc.exists) {
            let data = f.objectSnakeToCamelCase(doc.data(), false);

            if (!data.modelType) {
              data.modelType = "DISC";
              data.reportType = "EXTENDED_DISC";
            }

            switch (data.modelType) {
              case "DISC":
                const processedData = percentages.get(data);
                disc.populate(processedData);
                processedData.descriptors = descriptors;
                delete processedData.receiverName;
                delete processedData.receiverEmail;
                delete processedData.accountId;
                return processedData;

              case "PGC":
                return data;
              case "ONE_TO_ONE":
                return parseOneToOneAnswers(data);
              case "F1_TEAM":
                return parseF1TeamAnswers(data);
              case "SELF_LEADERSHIP":
                return parseSelfLeadAnswers(data);
            }
          } else {
            throw new Error(`Assessment not found for Report Id = ${reportId}`);
          }
        } else {
          throw new Error(`Assessment not found for Report Id = ${reportId}`);
        }
      } catch (error) {
        throw error;
      }
    } else {
      throw new Error("Se requiere Id del Reporte");
    }
  },
  listAssessments: async opts => {
    const currentUser = firebase.auth().currentUser;

    if (!currentUser) {
      throw new Error(
        "Assessment list could not be retrieved. Invalid current user."
      );
    }

    const userToken = await currentUser.getIdToken();

    const { data: response } = await instance.get(`/assessment/`, {
      headers: {
        token: userToken
      },
      params: { ...opts }
    });

    return {
      count: response.count,
      data: response.data.map(d => ({
        ...d,
        statusText: ASSESSMENT_STATUS[d.status],
        sentAt: new Date(d.sentAt).toLocaleDateString("es-ES"),
        submittedAt: d.submittedAt
          ? new Date(d.submittedAt).toLocaleDateString("es-ES")
          : "-"
      }))
    };
  },
  resendAssessment: async (assessment, assessmentId) => {
    const newAssessment = f.objectCamelToSnakeCase(assessment, false);
    await instance.put(`/assessment/${assessmentId}/resend`, newAssessment);
  },
  deleteAssessment: async assessmentId => {
    await instance.delete(`/assessment/${assessmentId}`);
  },
  closeAssessment: async assessmentId => {
    await instance.put(`/assessment/${assessmentId}/close`);
  }
};

function parseDISCAnswers(answers) {
  const consciousProfileId = [];
  const primaryProfileId = [];
  const consciousCounters = { D: 0, I: 0, S: 0, C: 0 };
  const primaryCounters = { D: 0, I: 0, S: 0, C: 0 };

  assessmentModel.questions.forEach((question, i) => {
    const answerThatMost = answers[i]["up"].index;
    const answerThatLess = answers[i]["dw"].index;

    const attributeThatMost = question.attributes[answerThatMost];
    const attributeThatLess = question.attributes[answerThatLess];

    const mt = attributeThatMost.value;
    const lt = attributeThatLess.value;

    consciousProfileId.push(mt);
    primaryProfileId.push(lt);

    if (hasToCount(mt, i)) {
      consciousCounters[mt]++;
    }
    if (hasToCount(lt, i)) {
      primaryCounters[lt]++;
    }
  });

  return {
    primary: {
      factorsPerAnswer: primaryProfileId.join(""),
      values: primaryCounters
    },
    conscious: {
      factorsPerAnswer: consciousProfileId.join(""),
      values: consciousCounters
    }
  };
}

function hasToCount(factor, questionIndex) {
  if (factor === "D" && [2, 17, 27].includes(questionIndex)) {
    // Count 0 for questions 3, 18, 28
    return false;
  } else if (factor === "C" && [13, 16].includes(questionIndex)) {
    // Count 0 for questions 14, 17
    return false;
  }
  return true;
}

function parsePGCAnswers(answers) {
  const result = {};

  answers.forEach(answer => {
    result[answer] = (result[answer] || 0) + 1;
  });

  return result;
}

function parseOneToOneAnswers(data) {
  const { applicants, results } = data;
  const { assessed, ...rest } = results;

  const job = sumArray([...assessed.job]);
  const motivation = sumArray([...assessed.motivation]);
  const relationships = sumArray([...assessed.relationships]);

  const answerCounts = {
    job: new Array(7).fill(undefined).map(() => ({})),
    motivation: new Array(7).fill(undefined).map(() => ({})),
    relationships: new Array(7).fill(undefined).map(() => ({})),
    aspects: {}
  };

  for (const applicantId in rest) {
    for (const category in rest[applicantId]) {
      for (const questionIndex in rest[applicantId][category]) {
        const value = rest[applicantId][category][questionIndex];

        if (category === "aspects") {
          if (!answerCounts[category][value]) {
            answerCounts[category][value] = 0;
          }
          answerCounts[category][value]++;
        } else {
          if (!answerCounts[category][questionIndex][value]) {
            answerCounts[category][questionIndex][value] = 0;
          }
          answerCounts[category][questionIndex][value]++;
        }
      }
    }
  }

  return {
    ...data,
    results: {
      assessedAnswers: assessed,
      assessedCounts: {
        job,
        motivation,
        relationships
      },
      answerCounts,
      applicantsCount: applicants ? applicants.length : 0
    }
  };
}

function parseF1TeamAnswers(data) {
  const { assessed, results } = data;
  const { applicant, ...rest } = results;

  // Sums values of all the answers of the applicant
  const applicantCount = f1TeamQuestions.reduce(
    (prev, curr) => prev + applicant[curr.key],
    0
  );

  // Prepares an object to store the counts by factor
  const assessedCountByFactor = f1TeamQuestions.reduce(
    (prev, curr) => ({
      ...prev,
      [curr.key]: {}
    }),
    {}
  );

  // Sums the chosen answer by factor
  for (const assessedId in rest) {
    for (const factor in assessedCountByFactor) {
      if (rest[assessedId][factor] !== undefined) {
        const answer = rest[assessedId][factor];

        if (assessedCountByFactor[factor][answer] === undefined) {
          assessedCountByFactor[factor][answer] = 0;
        }
        assessedCountByFactor[factor][answer] += 1;
      }
    }
  }

  // Prepares an object to store the sum of values by assessed
  const countByAssessed = {};

  // Sums the values by assessed
  for (const assessedId in rest) {
    for (const factor in assessedCountByFactor) {
      if (rest[assessedId][factor] !== undefined) {
        const answer = rest[assessedId][factor];

        if (countByAssessed[assessedId] === undefined) {
          countByAssessed[assessedId] = 0;
        }
        countByAssessed[assessedId] += answer;
      }
    }
  }

  // Prepares an object to store the count of assessed by level
  const assessedByLevel = f1TeamLevels.reduce(
    (prev, curr) => ({
      ...prev,
      [curr.level]: 0
    }),
    {}
  );

  // Counts the assessed by level
  Object.values(countByAssessed).forEach(count => {
    const level = f1TeamLevels.find(level => count <= level.range.max);
    assessedByLevel[level.level]++;
  });

  return {
    ...data,
    results: {
      applicantAnswers: applicant,
      applicantCount,
      assessedCountByFactor,
      assessedByLevel
    }
  };
}

function parseSelfLeadAnswers(data) {
  const { results } = data;
  const { emotion, mind, behavior } = results;

  const emotionsum = sumArray(emotion);
  const mindsum = sumArray(mind);
  const behaviorsum = sumArray(behavior);

  const totalSum = emotionsum + mindsum + behaviorsum;

  return {
    ...data,
    results: {
      applicantCount: {
        emotionsum,
        mindsum,
        behaviorsum
      },
      answerCounts: totalSum,
      emotion,
      mind,
      behavior
    }
  };
}
