<template>
  <v-container class="py-0">
    <v-row no-gutters justify="center" align="center">
      <v-col cols="6" class="d-flex align-center">
        <div class="node black-background d-inline-block" />
        <report-section-subtitle
          text="Estilo Natural"
          image="ICONOS-01.png"
          :subtext="primaryName"
        />
      </v-col>
      <v-col cols="6" class="d-flex align-center">
        <div class="node white-background d-inline-block" />
        <report-section-subtitle
          text="Respuesta Adaptativa"
          image="ICONOS-02.png"
          :subtext="consciousName"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "GraphLegend",
  props: {
    primaryName: {
      type: String,
      required: false
    },
    consciousName: {
      type: String,
      required: false
    }
  }
};
</script>

<style scoped>
.node {
  width: 2em;
  height: 2em;
  border-radius: 25px;
  float: left;
  border-color: black;
  border-style: solid;
}
</style>
