import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

import auth from "./modules/auth";
import assessment from "./modules/assessment";
import shop from "./modules/shop";
import dashboard from "./modules/dashboard";

export default new Vuex.Store({
  modules: {
    assessment,
    auth,
    dashboard,
    shop
  },
  plugins: [
    new VuexPersistence({
      key: "liderlab-app",
      storage: window.localStorage,
      modules: ["auth"]
    }).plugin
  ]
});
