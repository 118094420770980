<template>
  <div class="pagebreakAfter">
    <div class="white">
      <v-row no-gutters justify="center">
        <v-col cols="10" align="center">
          <div class="sectionTitle primary--text mt-md-12 mt-5">
            MAPA DE COMPORTAMIENTO
          </div>

          <div class="lineDividerDark mb-8" />
          <xs-rotation-message />

          <section class="d-none d-sm-block no-print">
            <h3>VER</h3>
            <v-row no-gutters class="justify-content-center">
              <v-radio-group v-model="viewMode" row>
                <v-radio label="TODO" value="BOTH" />
                <v-radio label="SOLO NATURAL" value="NATURAL" />
                <v-radio label="SOLO RESPUESTA ADAPTATIVA" value="ADAPTED" />
                <v-radio label="DIVIDIDO" value="SPLITTED" />
              </v-radio-group>
            </v-row>
            <br />
          </section>
        </v-col>
      </v-row>

      <div class="d-none d-sm-block d-print-block">
        <v-row no-gutters>
          <v-col cols="0" sm="0" md="1" />
          <v-col cols="12" sm="12" md="10" class="px-5 px-sm-5 px-md-0">
            <v-row no-gutters>
              <v-col
                :cols="isSplitted ? 6 : 12"
                :class="isSplitted ? 'pr-2' : ''"
              >
                <o-octagon :data="dataLeft"></o-octagon>
              </v-col>
              <v-col
                v-if="isSplitted"
                cols="6"
                :class="isSplitted ? 'pl-2' : ''"
              >
                <o-octagon :data="dataRight"></o-octagon>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="0" sm="0" md="1" />
        </v-row>

        <graph-legend />
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";

import { Octagon } from "../../common/octagon";
import graphLegend from "../../common/GraphLegend";

export default defineComponent({
  name: "TeamGraph",
  props: {
    data: Array
  },
  components: {
    "o-octagon": Octagon,
    graphLegend
  },
  data() {
    return {
      viewMode: "BOTH"
    };
  },
  computed: {
    dataLeft() {
      const { viewMode, data } = this;
      const result = [];

      if (data) {
        data.forEach(d => {
          if (viewMode !== "ADAPTED") {
            result.push({
              ...d.profiles.primary,
              applicantName: d.applicantName,
              profileType: "P",
              reportId: d.reportId
            });
          }

          if (viewMode === "BOTH" || viewMode === "ADAPTED") {
            result.push({
              ...d.profiles.conscious,
              applicantName: d.applicantName,
              profileType: "C",
              reportId: d.reportId
            });
          }
        });
      }

      return result;
    },
    dataRight() {
      const { viewMode, data } = this;
      const result = [];

      if (data && viewMode === "SPLITTED") {
        data.forEach(d => {
          result.push({
            ...d.profiles.conscious,
            applicantName: d.applicantName,
            profileType: "C",
            reportId: d.reportId
          });
        });
      }

      return result;
    },
    isSplitted() {
      return this.viewMode === "SPLITTED";
    }
  }
});
</script>

<style lang="css">
.align-center {
  text-align: center;
}
.sectionTitle {
  font-size: 2em;
  font-weight: 600;
}
@media print {
  .graphLabels {
    float: left;
    margin-left: 2em;
  }
  .no-print {
    display: none !important;
  }
}
.justify-content-center {
  justify-content: center;
}
.hide {
  display: none;
}
</style>
