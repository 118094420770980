<template>
  <div id="" class="section-background">
    <report-section-title>
      ¿Listo para conocer tus resultados?
    </report-section-title>

    <v-container fluid>
      <v-row no-gutters>
        <v-col no-gutters cols="0" md="1" />
        <v-col no-gutters cols="12" md="10" class="px-sm-1">
          <p class="intro-text">
            Este reporte te permitirá comprender tu perfil de comportamiento a
            través de tu estilo natural y de cómo te estás adaptando como
            respuesta al medio ambiente. Está basado en las cuatro (4)
            dimensiones del comportamiento normal descritas por el Dr. William
            Moulton Marston. Los resultados responden a la pregunta ¿cómo una
            persona hace las cosas?
            <br /><br />
            La psicología afirma que, las personas que se conocen a sí mismas y
            actúan honrando su esencia verdadera, logran un mejor rendimiento,
            pudiendo alcanzar niveles de genialidad. La información que contiene
            este reporte te brinda la oportunidad de aumentar tu
            autoconocimiento y fortalecer la alineación de tus fortalezas con tu
            verdadera esencia y motivación.
          </p>
        </v-col>
        <v-col cols="0" md="1" />
      </v-row>
    </v-container>

    <div id="four_dimensions" class="four-factors">
      <report-section-title theme="dark">
        Cuatro Dimensiones del Comportamiento Normal
      </report-section-title>

      <v-row no-gutters class="px-5">
        <v-col cols="0" md="1" />
        <v-col cols="12" md="10">
          <v-row>
            <v-col cols="6" sm="3" v-for="factor in factors" :key="factor.id">
              <factor-card :factor="factor.id">
                {{ factor.description }}
              </factor-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="0" md="1" />
      </v-row>
    </div>
  </div>
</template>

<script>
import FactorCard from "./FactorCard.vue";

export default {
  name: "SectionReadyToKnow",
  components: {
    "factor-card": FactorCard
  },
  data() {
    return {
      factors: [
        {
          id: "dominance",
          description:
            "Enfocado en la obtención rápida de resultados y solución de problemas."
        },
        {
          id: "influence",
          description:
            "Enfocado en las relaciones interpersonales y en la expresión de emociones."
        },
        {
          id: "serenity",
          description:
            "Enfocado en el mantenimiento del ritmo constante, la persistencia y la estabilidad."
        },
        {
          id: "caution",
          description:
            "Enfocado en el cumplimiento de los protocolos, las normas y estándares de calidad."
        }
      ]
    };
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

.section-background {
  background-color: #fff;
  color: var(--v-primary-base);
  font-family: Roboto;
}
.intro-text {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: var(--v-primary-base);
  text-align: justify;
}
.four-factors {
  background: linear-gradient(
    45deg,
    var(--v-primary-base) 0%,
    var(--v-primary-lighten2) 100%
  );
  padding-bottom: 1em;
}
@media print {
  .intro-text {
    margin-top: -1em;
  }
  .four-factors {
    padding-bottom: 1em;
  }
  .col-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
}
</style>
