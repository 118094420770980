<template>
  <o-card-form-layout :title="title" :actions="actions" page-mode="mid">
    <v-container class="pb-0">
      <v-row>
        <v-col cols="12" sm="6" class="py-0 px-1 text-left">
          <o-select
            uid="modelType"
            label="Herramienta"
            item-text="label"
            item-value="id"
            required
            v-model="form.modelType"
            :clearable="false"
            :data="modelTypes"
            @change="onChangeModelType"
          />
        </v-col>

        <v-col cols="12" sm="6" class="py-0 px-1">
          <o-select
            uid="reportType"
            label="Tipo de Reporte"
            item-text="label"
            item-value="id"
            v-model="form.reportType"
            :clearable="false"
            :data="filteredReportTypes"
            :required="true"
          />
        </v-col>
      </v-row>

      <!-- TODO: Encabezado divisorio -->

      <v-row>
        <v-col
          cols="12"
          sm="6"
          class="py-0 px-1"
          v-if="showByModelType(['DISC', 'PGC', 'F1_TEAM', 'SELF_LEADERSHIP'])"
        >
          <o-email-field
            v-model="form.applicantEmail"
            label="Correo Electrónico del Aplicante"
            :disabled="isStatusSubmitted"
            @keydown="syncApplicantEmail"
            @change="syncApplicantEmail"
          />
        </v-col>

        <!-- DISC, PGC, F1_TEAM, SELF_LEADERSHIP -->
        <v-col
          cols="12"
          sm="6"
          class="py-0 px-1"
          v-if="showByModelType(['DISC', 'PGC', 'F1_TEAM', 'SELF_LEADERSHIP'])"
        >
          <o-text-field
            v-model="form.applicantName"
            label="Nombre(s) y Apellido(s) del Aplicante"
            required
            :disabled="isStatusSubmitted"
            @keydown="syncApplicantName"
            @change="syncApplicantName"
          />
        </v-col>

        <!-- PGC, ONE_TO_ONE-->
        <v-col
          cols="12"
          sm="6"
          class="py-0 px-1"
          v-if="showByModelType(['ONE_TO_ONE'])"
        >
          <o-text-field
            v-model="form.assessedEmail"
            label="Correo Electrónico del Evaluado"
            required
            :disabled="isStatusSubmitted"
          />
        </v-col>

        <v-col
          cols="12"
          sm="6"
          class="py-0 px-1"
          v-if="showByModelType(['PGC', 'ONE_TO_ONE'])"
        >
          <o-text-field
            v-model="form.assessedName"
            label="Nombre(s) y Apellido(s) del Evaluado"
            required
            :disabled="isStatusSubmitted"
          />
        </v-col>

        <!-- F1_TEAM -->
        <v-col
          cols="12"
          sm="6"
          class="py-0 px-1"
          v-if="showByModelType(['F1_TEAM'])"
        >
          <o-text-field
            v-model="temp.email"
            label="Correo Electrónico del Evaluado Adicional"
            :disabled="isStatusSubmitted"
          />
        </v-col>

        <v-col
          cols="12"
          sm="6"
          class="py-0 px-1 d-flex justify"
          v-if="showByModelType(['F1_TEAM'])"
        >
          <o-text-field
            v-model="temp.name"
            label="Nombre(s) y Apellido(s) del Evaluado Adicional"
            :disabled="isStatusSubmitted"
          />

          <v-btn fab small color="primary" class="mx-2" @click="addAssessed">
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
        </v-col>

        <v-col cols="12" class="py-0 px-1" v-if="showByModelType(['F1_TEAM'])">
          <v-chip-group v-model="form.assessed" column class="pb-6">
            <v-chip
              v-for="({ name, email }, index) in form.assessed"
              :key="index"
              close
              @click:close="removeAssessed(index)"
            >
              {{ name ? `${name} (${email})` : `${email}` }}
            </v-chip>
          </v-chip-group>
        </v-col>

        <v-col
          cols="12"
          sm="6"
          class="py-0 px-1 .d-none .d-sm-flex"
          v-if="showByModelType(['PGC'])"
        />

        <!-- ONE_TO_ONE -->
        <v-col
          cols="12"
          sm="6"
          class="py-0 px-1"
          v-if="showByModelType(['ONE_TO_ONE'])"
        >
          <o-text-field
            v-model="temp.email"
            label="Correo Electrónico del Aplicante Adicional"
            :disabled="isStatusSubmitted"
          />
        </v-col>

        <v-col
          cols="12"
          sm="6"
          class="py-0 px-1 d-flex justify"
          v-if="showByModelType(['ONE_TO_ONE'])"
        >
          <o-text-field
            v-model="temp.name"
            label="Nombre(s) y Apellido(s) del Aplicante Adicional"
            :disabled="isStatusSubmitted"
          />

          <v-btn fab small color="primary" class="mx-2" @click="addApplicant">
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
        </v-col>

        <v-col
          cols="12"
          class="py-0 px-1"
          v-if="showByModelType(['ONE_TO_ONE'])"
        >
          <v-chip-group v-model="form.applicants" column class="pb-6">
            <v-chip
              v-for="({ name, email }, index) in form.applicants"
              :key="index"
              close
              @click:close="removeApplicant(index)"
            >
              {{ name ? `${name} (${email})` : `${email}` }}
            </v-chip>
          </v-chip-group>
        </v-col>

        <v-col cols="12" sm="6" class="py-0 px-1">
          <o-text-field
            v-model="form.company"
            label="Empresa (opcional)"
            :disabled="isStatusSubmitted"
          />
        </v-col>

        <v-col cols="12" sm="6" class="py-0 px-1">
          <o-text-field
            v-model="form.position"
            label="Cargo (opcional)"
            :disabled="isStatusSubmitted"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="py-0 px-1 text-left text-subtitle-2">
          Envío de Resultados
        </v-col>

        <v-col cols="12" class="py-0 px-1">
          <v-checkbox v-model="form.applicantReceives" :label="checkboxLabel" />
        </v-col>

        <v-col cols="12" sm="6" class="py-0 px-1">
          <o-email-field
            v-model="form.receiverEmail"
            label="Correo Electrónico del Receptor"
            required
            :disabled="form.applicantReceives"
          />
        </v-col>

        <v-col cols="12" sm="6" class="py-0 px-1">
          <o-text-field
            v-model="form.receiverName"
            label="Nombre(s) y Apellido(s) del Receptor"
            required
            :disabled="form.applicantReceives"
          />
        </v-col>
      </v-row>
    </v-container>
  </o-card-form-layout>
</template>

<script lang="js">
import { defineComponent } from "vue";
import assessmentMixin from "@/mixins/assessmentMixin";
import { MODEL_TYPES, REPORT_TYPES } from "@/model/assessment/constants";

const modelTypes = Object.entries(MODEL_TYPES).map(([key, value]) => ({
  id: key,
  label: value
}));

const reportTypes = Object.entries(REPORT_TYPES).map(
  ([key, { label, modelType }]) => ({
    id: key,
    label,
    modelType
  })
);

export default defineComponent({
  mixins: [assessmentMixin],
  data() {
    return {
      mode: null,
      reportTypes: reportTypes,
      temp: {
        email: "",
        name: ""
      },
      form: {
        modelType: "",
        reportType: "",
        applicantEmail: "", // DISC, PCG, F1_TEAM, SELF_LEADERSHIP
        applicantName: "", // DISC, PCG, F1_TEAM, SELF_LEADERSHIP
        assessedName: "", // PCG, ONE_TO_ONE
        assessedEmail: "", // ONE_TO_ONE, F1_TEAM
        applicants: [], // ONE_TO_ONE
        assessed: [], // F1_TEAM
        company: "",
        position: "",
        applicantReceives: false,
        receiverEmail: "",
        receiverName: ""
      },
      actions: [
        { type: "submit", label: "Enviar", handler: this.send },
        {
          type: "secondary",
          label: "Volver Atrás",
          to: "/"
        }
      ]
    };
  },
  computed: {
    filteredReportTypes() {
      return this.reportTypes.filter(
        reportType => reportType.modelType === this.form.modelType
      );
    },
    isModeSend() {
      return this.mode === "SEND";
    },
    isModeResend() {
      return this.mode === "RESEND";
    },
    isStatusSubmitted() {
      return this.assessment.status === "SUBMITTED";
    },
    title() {
      if (this.mode === "SEND") {
        return "Enviar Prueba";
      } else if (this.mode === "RESEND") {
        if (this.assessment.status === "SENT") {
          return "Reenviar Prueba";
        } else if (this.assessment.status === "SUBMITTED") {
          return "Reenviar Reporte";
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    successMessage() {
      if (this.mode === "SEND") {
        return "La prueba ha sido enviada con éxito";
      } else if (this.mode === "RESEND") {
        if (this.assessment.status === "SENT") {
          return "La prueba ha sido enviada con éxito";
        } else if (this.assessment.status === "SUBMITTED") {
          return "El reporte ha sido enviado con éxito";
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    checkboxLabel() {
      return ["DISC", "PGC", "F1_TEAM", "SELF_LEADERSHIP"].includes(this.form.modelType)
        ? "Aplicante"
        : "Evaluado";
    },
    modelTypes() {
      return modelTypes.filter(({ id }) => this.isModelTypeEnabled(id));
    }
  },
  methods: {
    removeApplicant(index) {
      this.form.applicants.splice(index, 1);
    },
    removeAssessed(index) {
      this.form.assessed.splice(index, 1);
    },
    addApplicant() {
      this.form.applicants.push({ ...this.temp });
      this.temp.email = "";
      this.temp.name = "";
    },
    addAssessed() {
      this.form.assessed.push({ ...this.temp });
      this.temp.email = "";
      this.temp.name = "";
    },
    back() {
      this.$router.push("/");
    },
    onChangeModelType(_) {
      const reportTypes = this.filteredReportTypes;

      if (reportTypes.length === 1) {
        this.form.reportType = reportTypes[0].id;
      } else {
        this.form.reportType = "";
      }
    },
    isModelTypeEnabled(modelType) {
      if (!["ONE_TO_ONE", "F1_TEAM", "SELF_LEADERSHIP"].includes(modelType)) {
        return true;
      }

      const { features } = this.$store.getters.accountInfo;

      if (features) {
        switch(modelType) {
          case "ONE_TO_ONE":
            return features.enableOneToOne;

          case "F1_TEAM":
            return features.enableF1Team;

          case "SELF_LEADERSHIP":
            return features.enableSelfLeadership;
        }
      }

      return false;
    },
    send(form) {
      if (form.validate()) {
        const accountInfo = this.$store.getters.accountInfo;
        const user = this.$store.getters.user;
        if (accountInfo && user) {
          // TODO: Get userId at Cloud Function
          let promise = null;
          if (this.isModeSend) {
            promise = this.$store.dispatch("createAssessment", {
              ...this.form,
              accountId: accountInfo.id,
              sentBy: user.id
            });
          } else if (this.isModeResend) {
            for (let attr in this.form) {
              this.assessment[attr] = this.form[attr];
            }
            promise = this.$store.dispatch("resendAssessment", {
              assessment: this.assessment,
              assessmentId: this.assessmentId
            });
          }

          promise
            .then(_ => {
              this.successDialog(this.successMessage, () => {
                if (this.isModeSend) {
                  form.reset();
                  this.resetForm();
                } else if (this.isModeResend) {
                  this.back();
                }
              });
            })
            .catch(error => {
              console.error(error);
              this.errorDialog("Ha ocurrido un error en el envío", this.back);
            });
        } else {
          this.errorDialog(
            "Información de Cuenta o de Usuario inválida",
            this.back
          );
        }
      }
    },
    resetForm() {
      this.form.modelType = "";
      this.form.reportType = "";
      this.form.applicantEmail = "";
      this.form.applicantName = "";
      this.form.assessedName = "";
      this.form.assessedEmail = "";
      this.form.applicants = [];
      this.form.assessed = [];
      this.form.company = "";
      this.form.position = "";
      this.form.applicantReceives = false;
      this.form.receiverEmail = "";
      this.form.receiverName = "";
    },
    showByModelType(supportedModelTypes) {
      return supportedModelTypes.includes(this.form.modelType);
    },
    syncApplicantEmail() {
      if (this.form.applicantReceives) {
        this.form.receiverEmail = this.form.applicantEmail;
      }
    },
    syncApplicantName() {
      if (this.form.applicantReceives) {
        this.form.receiverName = this.form.applicantName;
      }
    },
    init() {
      if (this.isModeResend) {
        this.loadAssessment()
          .then(assessment => {
            this.form.modelType = assessment.modelType || "DISC";
            this.form.reportType = assessment.reportType || "";
            this.form.applicantEmail = assessment.applicantEmail || "";
            this.form.applicantName = assessment.applicantName || "";
            this.form.assessedName = assessment.assessedName || "";
            this.form.assessedEmail = assessment.assessedEmail || "";
            this.form.applicants = assessment.applicants || [];
            this.form.assessed = assessment.assessed || [];
            this.form.applicantReceives = assessment.applicantReceives || false;
            this.form.company = assessment.company || "";
            this.form.position = assessment.position || "";
            this.form.receiverEmail = assessment.receiverEmail || "";
            this.form.receiverName = assessment.receiverName || "";
          })
          .catch(error => {
            this.$store.dispatch("clearAssessment");
            this.showErrorAndBack(
              "Ha ocurrido un error leyendo los datos de la prueba"
            );
          });
      } else {
        this.form.modelType = "DISC";
        this.form.reportType = "";
        this.form.applicantEmail = "";
        this.form.applicantName = "";
        this.form.assessedName = "";
        this.form.assessedEmail = "";
        this.form.applicants = [];
        this.form.assessed = [];
        this.form.applicantReceives = false;
        this.form.company = "";
        this.form.position = "";
        this.form.receiverEmail = "";
        this.form.receiverName = "";
      }
    }
  },
  watch: {
    "form.applicantReceives": function (value) {
      if (value) {
        if (this.form.modelType === "ONE_TO_ONE") {
          this.form.receiverEmail = this.form.assessedEmail;
          this.form.receiverName = this.form.assessedName;
        } else {
          this.form.receiverEmail = this.form.applicantEmail;
          this.form.receiverName = this.form.applicantName;
        }
      } else {
        this.form.receiverEmail = "";
        this.form.receiverName = "";
      }
    }
  },
  created() {
    if (this.assessmentId) {
      this.mode = "RESEND";
    } else {
      this.mode = "SEND";
    }

    this.init();
  }
});
</script>

<style></style>
