export default {
  dominance: {
    name: "Dominancia",
    adjective: "Dominante",
    letter: "D",
    description:
      "Enfocado en la obtención rápida de resultados y solución de problemas.",
    color: "var(--v-error-base)",
    colorName: "rojo",
    rgb: "255, 82, 82",
    backgroundStyle: "error",
    textStyle: "white--text",
    communicationTips: {
      qualities:
        "Enérgico, independiente, orientado a resultados, creativo, ambicioso.",
      toDo: "Ir directamente al asunto. Ser claro, específico, breve y concreto. Estar preparado para responder a las preguntas. Aportar material de apoyo bien organizado. Ofrecer soluciones.",
      toAvoid:
        "Hablar de cosas poco relevantes. Perder tiempo, divagar. Ser evasivo y poco claro. Parecer desorganizado. Establecer vínculos personales. Presentar temas con los que no se siente seguro."
    },
    descriptors: {
      up: [
        "Inquieto",
        "Original",
        "Contundente",
        "Firme",
        "Solucionador",
        "Con iniciativa",
        "Competitivo",
        "Individualista",
        "Emprendedor",
        "Cuestionador",
        "Responsable",
        "Audaz",
        "Rápido",
        "Directo",
        "Curioso"
      ],
      down: [
        "Sereno",
        "Pausado",
        "Paciente",
        "Cooperativo",
        "Armonioso",
        "Comprensivo",
        "Imparcial",
        "Discreto",
        "Gentil",
        "Humilde",
        "Moderado",
        "Predecible",
        "Estable",
        "Tradicionalista",
        "Complaciente"
      ]
    },
    question: "¿Cómo respondes a los nuevos problemas?",
    factorPower: {
      keyConcept: "Los resultados",
      extendedLeftText:
        "Actitud amable, amigable, discreta y gentil. Comportamiento cauteloso, moderado y precavido. Prefiere la rutina que la novedad. Respuesta premeditada. Agradable. Actitud reflexiva, humilde y pacífica. Cumple con métodos de forma sistemática. Condescendiente. Trabaja en equipo.",
      simplifiedLeftText:
        "<b>Liderazgo Gentil</b><br>Líder amable, agradable, de actitud complaciente y gentil. Visión de detalle. Resuelve con rapidez los problemas rutinarios e invita a pensar muy bien la solución de los nuevos problemas. Liderazgo reflexivo. Humilde, de actitud pacífica. Motiva la cooperación y el trabajo de equipo.",
      extendedRightText:
        "Ágil resolviendo problemas nuevos. Responde de forma muy rápida, contundente y determinada. Obtiene resultados rápidos. De actitud activa, directa y arriesgada. Comportamiento creativo e innovador. Actitud agresiva y competitiva.",
      simplifiedRightText:
        "<b>Liderazgo Dominante</b><br>Líder con visión global y orientación al futuro. Ágil en la obtención de resultados. Ofrece soluciones creativas a problemas complejos. Espíritu emprendedor e innovador. Toma riesgos. Independiente. Controla. Da órdenes. Fuerza el compromiso de los demás. Ritmo rápido. Beneficia y valora la obtención de resultados más que la armonía en las relaciones."
    }
  },
  influence: {
    name: "Influencia",
    adjective: "Influyente",
    letter: "I",
    description:
      "Enfocado en las relaciones interpersonales y en la expresión de emociones.",
    color: "var(--v-warning-base)",
    colorName: "amarillo",
    rgb: "255, 193, 7",
    backgroundStyle: "warning",
    textStyle: "warning--text text--darken-4",
    communicationTips: {
      qualities:
        "Jovial, expresivo, optimista, amigable, carismático, conversador.",
      toDo: "Crear un ambiente cálido. Dejar que hable. Hacer preguntas que le permitan conocer sus opiniones. Tomar en cuenta sus recomendaciones. Permitir que fluya una relación amistosa. Invertir tiempo en socializar.",
      toAvoid:
        " Ser distante, frío o callado. Controlar la conversación. Mostrar la información relevante de primero. Entrar en demasiados detalles. Centrar la conversación en las tareas y procesos."
    },
    descriptors: {
      up: [
        "Entusiasta",
        "Elocuente",
        "Comunicativo",
        "Carismático",
        "Jovial",
        "Sociable",
        "Alegre",
        "Optimista",
        "Divertido",
        "Expresivo",
        "Espontáneo",
        "Demostrativo",
        "Popular",
        "Confiado",
        "Cercano"
      ],
      down: [
        "Objetivo",
        "Planificado",
        "Distante",
        "Tímido",
        "Introvertido",
        "Formal",
        "Preciso",
        "Riguroso",
        "Metódico",
        "Perfeccionista",
        "Cuidadoso",
        "Paciente",
        "Discreto",
        "Investigador",
        "Normativo"
      ]
    },
    question: "¿Cómo te relacionas con los demás?",
    factorPower: {
      keyConcept: "Las relaciones",
      extendedLeftText:
        "Relaciones profundas y estables con personas del entorno conocido. Poca expresividad emocional. Actitud escéptica, reflexiva, crítica y analítica. Distante. Racional. Pensamiento lógico. Introspección. De actitud contemplativa.",
      simplifiedLeftText:
        "<b>Liderazgo Racional</b><br>Líder con enfoque en la objetividad. Se basa en los hechos para decidir. No confía fácilmente. Comportamiento reflexivo, critico y analítico para relacionarse. Actitud poco demostrativa emocionalmente. Carácter discreto e introspectivo.",
      extendedRightText:
        "De actitud extrovertida a la hora de hacer nuevas amistades que se refleja en apertura y confianza hacia los demás. Facilidad para conversar de forma sociable y amena. Actitud impulsiva y dinámica. Personalidad carismática, optimista, persuasiva y convincente. Emocional.",
      simplifiedRightText:
        "<b>Liderazgo Entusiasta</b><br>Líder con facilidad para relacionarse y conectar con personas claves y con extraños. Promueve ideas y motiva a las personas. Optimista y entusiasta. Sabe crear un clima dinámico y de integración. Logra resultados a través de las personas. Sociable. Valora los gestos de gratitud. Actitud impulsiva. Emocional."
    }
  },
  serenity: {
    name: "Serenidad",
    adjective: "Sereno",
    letter: "S",
    description:
      "Enfocado en el mantenimiento del ritmo constante, la persistencia y la estabilidad.",
    color: "var(--v-success-base)",
    colorName: "verde",
    rgb: "76, 175, 80",
    backgroundStyle: "success",
    textStyle: "white--text",
    communicationTips: {
      qualities:
        "Paciente, reflexivo, estable, poca expresivo, modesto, amable, sabe escuchar.",
      toDo: "Empezar con un comentario personal que rompa el hielo. Presentar las ideas suavemente, sin tono amenazador. Indagar sobre sus opiniones. Hablar usando la lógica. Ser sistemático y metódico.",
      toAvoid:
        "Ir directo al grano. Ser impetuoso y precipitado. Ser dominante y exigente. Forzar a responder rápidamente. Hacer falsas promesas. Mostrarse apurado. Interrumpir mientras habla."
    },
    descriptors: {
      up: [
        "Servicial",
        "Metódico",
        "Sabe escuchar",
        "Paciente",
        "Complaciente",
        "Deliberado",
        "Tradicional",
        "Estable",
        "Leal",
        "Amigable",
        "Imparcial",
        "Apacible",
        "Obediente",
        "Constante",
        "Colaborador"
      ],
      down: [
        "Rápido",
        "Directo",
        "Atrevido",
        "Innovador",
        "Arriesgado",
        "Dominante",
        "Impaciente",
        "Impulsivo",
        "Animado",
        "Versátil",
        "Enérgico",
        "Flexible",
        "Original",
        "Individualista",
        "Contundente"
      ]
    },
    question: "¿Cómo te adaptas al ritmo del medio ambiente?",
    factorPower: {
      keyConcept: "La estabilidad",
      extendedLeftText:
        "De ritmo rápido. Impaciente. De actitud dinámica, flexible y audaz. Prefiere un ambiente libre de controles. Agente de cambio. Amante de la innovación. Expresa ideas libremente. La rutina le aburre. De carácter intenso, impulsivo y animado.",
      simplifiedLeftText:
        "<b>Liderazgo Activo</b><br>Líder de ritmo rápido y actitud arriesgada. Audaz. Crea un clima animado, de retos y desafíos. Valora el dinamismo sobre la estabilidad. De carácter intenso e impulsivo. Motiva el cambio. Trabaja bajo presión. Impaciente y alerta.",
      extendedRightText:
        "Paciente. De ritmo pausado. Prefiere la estabilidad al cambio. Trabaja en equipo. De actitud disciplinada y leal. Habilidad para escuchar y armonizar. Sigue métodos y sistemas. Consistente. De carácter calmado y reflexivo. Ecuánime. Predecible.",
      simplifiedRightText:
        "<b>Liderazgo Empático</b><br>Líder que concilia y armoniza. Crea un clima de formalidad y estabilidad. Ritmo pausado. Estimula y valora la colaboración y el trabajo de equipo. Leal. Motiva para mantener la tradición y los sistemas probados. Actitud serena, consistente, paciente y poco demostrativa. Ofrece apoyo. Promueve el cumplimiento del plan."
    }
  },
  caution: {
    name: "Cautela",
    adjective: "Cauteloso",
    letter: "C",
    description:
      "Enfocado en el cumplimiento de los protocolos, las normas y estándares de calidad.",
    color: "var(--v-info-base)",
    colorName: "azul",
    rgb: "33, 150, 243",
    backgroundStyle: "info",
    textStyle: "white--text",
    communicationTips: {
      qualities:
        "Exacto, analítico, consciente, equilibrado, cortés, diplomático, busca hechos, investiga.",
      toDo: "Ir preparado con análisis sustentados. Presentar la información de forma ordenada y limpia. Ser específico en la planificación y en los protocolos. Ir al grano. Ser preciso y realista. Usar datos e información técnica.",
      toAvoid:
        "Improvisar. Dejar cabos sueltos. Ser brusco. Levantar la voz. Ser desconcertante, ser informal. Ser poco realista con los plazos. Ser confuso. Presionar. Ser desorganizado. Estimular un clima íntimo. Hablar de las emociones."
    },
    descriptors: {
      up: [
        "Consistente",
        "Formal",
        "Organizado",
        "Preciso",
        "Cauteloso",
        "Reservado",
        "Cumplido",
        "Meticuloso",
        "Analítico",
        "Cortés",
        "Prudente",
        "Estructurado",
        "Objetivo",
        "Planificado",
        "Metódico"
      ],
      down: [
        "Popular",
        "Espontáneo",
        "Optimista",
        "Confiado",
        "Descuidado",
        "Promotor",
        "Rebelde",
        "Entusiasta",
        "Desafiante",
        "Extrovertido",
        "Cautivador",
        "Cercano",
        "Carismático",
        "Comunicativo",
        "Sociable"
      ]
    },
    question: "¿Cómo cumples con las normas y procedimientos?",
    factorPower: {
      keyConcept: "La cautela",
      extendedLeftText:
        "Original y de espíritu libre. Autosuficiente. Independiente y poco convencional. Autónomo e individualista. Confidente. Poco detallista. Con ingenio. Flexible. De actitud desinhibida, temeraria y arriesgada.",
      simplifiedLeftText:
        "<b>Liderazgo Intuitivo</b><br>Líder de actitud espontánea, determinada y original. De ideas poco convencionales. Invita a la flexibilidad de los protocolos. Poco cuidado por los detalles. Toma decisiones con base en sus deseos y no en el análisis de los hechos. Valora la libertad de acción sobre el cumplimiento de las reglas.",
      extendedRightText:
        "De actitud analítica, cautelosa, diplomática y apegada a los protocolos. Respeto por la autoridad y las reglas. Hace las cosas correctamente desde el inicio. Usa el control y el seguimiento para lograr altos estándares de calidad. Aprecia el orden y la limpieza. Perfeccionista.",
      simplifiedRightText:
        "<b>Liderazgo Metódico</b><br>Líder que planifica. Motiva la investigación y el análisis para tomar decisiones. Promueve la objetividad y la precisión en el manejo de la información. Liderazgo con base en el conocimiento. Valora el respeto por las reglas, el cuidado de los detalles y el cumplimiento de estándares de calidad más que lo social. Hace seguimiento."
    }
  }
};
